import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "私と地域Rubyコミュニティとメドレーの関わり",
  "date": "2024-12-12T09:00:14.000Z",
  "slug": "entry/2024/12/12/180014",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "私と地域Rubyコミュニティとメドレーの関わり"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Edit here! */
    }
    <p>{`こちらの記事は `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://qiita.com/advent-calendar/2024/medley"
        }}>{`Medley（メドレー） Advent Calendar 2024`}</a></strong>{` の12日目の記事です。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`はじめまして。医療プラットフォーム事業部　プロダクト開発室　Dentis開発グループでグループマネジャーをしている牧（X: `}<a parentName="p" {...{
        "href": "https://x.com/Kirika_K2"
      }}>{`@Kirika_K2`}</a>{`）と申します。`}</p>
    <p>{`メドレーには2019年に入社し、以来、歯科医院向けのクラウドサービス`}<a parentName="p" {...{
        "href": "https://dentis-cloud.com/"
      }}>{`Dentis`}</a>{`の立ち上げ、プロダクト開発に携わっています。`}<br parentName="p"></br>{`
`}{`Dentisは医療機関の予約・受付・問診・カルテの記録・会計業務・保険請求業務までをトータルでサポートするクラウドシステムで、主にRuby on RailsとReactで作られています。
日々歯科医療ドメインでお客様の声に向き合いながら、医療DXを推進しています。`}<br parentName="p"></br>{`
`}{`着実に成長しているプロダクトですので、もしご興味ありましたら、カジュアル面談等受け付けておりますので、お声がけください。（宣伝終）`}</p>
    <p>{`普段はプロダクト開発に従事していますが、プライベートでは地域Rubyコミュニティの表参道.rbを主催しておりまして、メドレーでも地域Rubyコミュニティ支援という形で、表参道.rbのスポンサー企業として、協力してもらっています。`}<br parentName="p"></br>{`
`}{`今回のAdvent Calendarでは、一地域Rubyコミュニティオーガナイザーの立場から見たメドレーの地域Rubyコミュニティとの関わりについて書こうと思います。`}</p>
    <h1>{`表参道.rbと私の関わり`}</h1>
    <p>{`表参道.rbは2015年頃から始まった、表参道周辺のエンジニアが集まってRubyの周辺技術に関する情報交換をする地域Rubyコミュニティです。`}<br parentName="p"></br>{`
`}{`毎月第１木曜日に開催しており、毎月1人10分程度のトークセッションを6〜７本と交流会を行っています。`}</p>
    <p>{`一部例外があり、開催日が祝日等と重なる場合は、1週間ずらして第2木曜日に開催しています。`}<br parentName="p"></br>{`
`}{`ちょうど今月の第1木曜日は島根県松江市で、RubyWorld Conference 2025が開催されており、表参道.rbに普段来てもらっている多くの方も松江に集合しているので、第2木曜日の開催になります。（つまり今日です！）  `}</p>
    <p>{`毎月第2週は、後ほどお話する六本木.rbが定期開催されており、合同開催という形での開催しています。`}<br parentName="p"></br>{`
`}{`どちらも楽しいコミュニティですので、是非遊びに来てください。`}</p>
    <p>{`表参道.rb`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://omotesandorb.connpass.com/"
      }}>{`https://omotesandorb.connpass.com/`}</a></p>
    <p>{`六本木.rb`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://roppongirb.connpass.com/"
      }}>{`https://roppongirb.connpass.com/`}</a></p>
    <p>{`私は、2018年頃から運営に関わり始め、その後メドレーに入社し、職場が六本木に変わった後も続けていました。  `}</p>
    <h1>{`コロナ禍によるオンライン開催移行、そしてオフライン開催の復活`}</h1>
    <p>{`2020年にコロナ禍になり、オフラインでの開催ができなくなり半年ほど開催を見送っていたこともありましたが、オンライン開催に移行して開催してきました。`}<br parentName="p"></br>{`
`}{`ちょうどコロナ禍で混乱していた頃、コミュニティを活動休止にするか、オンライン開催に移行するか悩みましたが、一度中止にしてしまうと再開するきっかけを失ってしまう可能性もあったため、これまで通りの頻度で開催することに決めました。`}<br parentName="p"></br>{`
`}{`またオンラインに移行するにあたって、もくもく会など、これまでと異なる形式での開催も考えましたが、いくつかのコミュニティがオンラインに移行している中、トークセッションがあるコミュニティは貴重、という意見をいただき、これまで通りの形式で進めていくことにしました。  `}</p>
    <p>{`オンライン開催の時は人の集まりが良くない時もあり、5名ぐらいで開催することもありましたが、毎回誰かが発表ネタを持ち込んでくれ、また発表が少なかった日は、少人数で余った時間をディスカッションに充てることができたので、これはこれで面白みがありました。`}<br parentName="p"></br>{`
`}{`また普段遠方で表参道.rbに参加できないような方にも参加していただいていたので、今も表参道.rbが続いているのは、当時少人数でも途切れないようにコミュニティを支えてくださった方々のお陰です。とてもありがたいことですね。`}<br parentName="p"></br>{`
`}{`御存知のとおり、コロナ禍は長く続くことになり、オンラインでの開催は2020年11月から2023年6月まで行われました。`}</p>
    <p>{`RubyKaigiがオフライン開催に戻り、2023年の松本での開催から東京に戻った後、表参道.rbもオフラインに戻すことを考え始めました。`}<br parentName="p"></br>{`
`}{`その際に必要になるのが開催する会場です。当時はまだオフラインで会場を提供していただける場所は多くなく、まず最初に相談したのは自分の勤務先であるメドレーでした。`}<br parentName="p"></br>{`
`}{`メドレーでは過去にRubyコミュニティのために会場提供をしたことはありませんでしたが、私が地域Rubyコミュニティの主催を継続していたこともあり、活動を後押ししていただけ、快く会場提供と懇親会の予算をつけてもらえました。`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">表参道.rb #87 では弊社が会場提供させていただいております!<a href="https://twitter.com/hashtag/omotesandorb?src=hash&amp;ref_src=twsrc%5Etfw">#omotesandorb</a> <a href="https://twitter.com/hashtag/ruby?src=hash&amp;ref_src=twsrc%5Etfw">#ruby</a><a href="https://t.co/PDmvLeqLPN">https://t.co/PDmvLeqLPN</a> <a href="https://twitter.com/hashtag/omotesandorb?src=hash&amp;ref_src=twsrc%5Etfw">#omotesandorb</a></p>&mdash; メドレー ディベロッパー公式 (@medley_dev) <a href="https://twitter.com/medley_dev/status/1669515426907422720?ref_src=twsrc%5Etfw">June 16, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`ありがたいことに、この回の表参道.rbに参加してくださった方の中から、「自分の会社でも会場提供したい」という申し出を受け、現在の表参道.rbは10社ぐらいの企業から会場スポンサードをしていただき、毎回開催会場を変えながら、継続しています。`}</p>
    <p>{`そして、今年2024年8月に表参道.rbはめでたく100回目の開催を迎えました。`}<br parentName="p"></br>{`
`}{`本当に様々な人に支えられて開催できた100回開催だな、と思います。  `}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">表参道rb 100回〜〜！！👏👏👏👏👏<a href="https://twitter.com/hashtag/omotesandorb?src=hash&amp;ref_src=twsrc%5Etfw">#omotesandorb</a> <a href="https://twitter.com/hashtag/roppongirb?src=hash&amp;ref_src=twsrc%5Etfw">#roppongirb</a> <a href="https://t.co/1qe2aJWMcZ">pic.twitter.com/1qe2aJWMcZ</a></p>&mdash; あっきー🍺ツクリンクEM (@kuronekopunk) <a href="https://twitter.com/kuronekopunk/status/1818976996480909574?ref_src=twsrc%5Etfw">August 1, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <h1>{`六本木.rbの復活とメドレーとの関わり`}</h1>
    <p>{`ちょうど今年のはじめぐらいの頃に、表参道.rbに参加されていたryoskさん(X: `}<a parentName="p" {...{
        "href": "https://x.com/ryosk7"
      }}>{`@ryosk7`}</a>{`)から六本木.rbを再始動させたいという話があり、会場を探しているという話がありました。`}<br parentName="p"></br>{`
`}{`六本木.rbは六本木周辺の企業で働くエンジニアが集まるRubyコミュニティで、コロナ前までは時々開催されていたようですが、こちらもコロナで休止していたコミュニティでした。`}<br parentName="p"></br>{`
`}{`メドレーは六本木の企業ということもあり、表参道.rb同様に六本木.rbも支援してもらいたいということで会社に相談したところ、こちらも会場提供と懇親会予算をつけてもらえることになりました。`}</p>
    <p>{`この時メドレーはRubyKaigi 2024のRubyスポンサーとして協賛しており、最終日の基調講演前のスポンサーセッション枠をもらったタイミングだったため、どうせなら、盛大に宣伝してもらおうということで、弊社VPoEの山崎にも地域.rb支援の件について紹介してもらいました。`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">ただいま発表がありましたが、<br />Roppongi.rb が復活します！！！🎉<br /><br />六本木周辺の企業さんのお力を借りてオフライン開催をします。<br />6月13日はメドレーさんのオフィスをお借りします。<br /><br />これからconnpassの方も展開します！<br />よろしくお願いします🙌<a href="https://twitter.com/hashtag/rubykaigi?src=hash&amp;ref_src=twsrc%5Etfw">#rubykaigi</a> <a href="https://t.co/zo2t6KGPrj">https://t.co/zo2t6KGPrj</a></p>&mdash; ryosk7🦎 (@ryosk7) <a href="https://twitter.com/ryosk7/status/1791374814281957551?ref_src=twsrc%5Etfw">May 17, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p><a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2024/05/24/182914"
      }}>{`RubyKaigi 2024 に Ruby Sponsor として協賛しました！ - MEDLEY Developers Portal`}</a></p>
    <p>{`それ以来、六本木.rbはメドレーを含む4社で月1回の定期開催しています。`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">メドレーさんのオフィスに着きました！<br />今日はこちらでRoppongi.rbを行います。<a href="https://twitter.com/hashtag/roppongirb?src=hash&amp;ref_src=twsrc%5Etfw">#roppongirb</a> <a href="https://t.co/UioFP3FEyY">pic.twitter.com/UioFP3FEyY</a></p>&mdash; ryosk7🦎 (@ryosk7) <a href="https://twitter.com/ryosk7/status/1801195679806783588?ref_src=twsrc%5Etfw">June 13, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <h1>{`地域Rubyコミュニティ支援に伴う、ポジティブな効果`}</h1>
    <p>{`このような活動を続けてきて、会社にどのような変化があったかについて触れたいと思います。`}<br parentName="p"></br>{`
`}{`まず1つ目は「普段の仕事で会わない社内の人たちと社外のイベントで顔を合わせるようになった」ことです。`}</p>
    <p><em parentName="p">{`2022年RubyKaigiの様子、この年はコロナ休止からのリブートということもあり、ブース出展もなかったので、自分しか参加してなかった`}</em>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3d9c42bba6e39b8200cdab4f28de3885/d2602/rubykaigi2022.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABe1g081wRD//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMSEyL/2gAIAQEAAQUCsnzin5KnsdmTqf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAAHAQAAAAAAAAAAAAAAAAABEBExQVEC/9oACAEBAAY/AqfASc6UvaQP/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERITFBkf/aAAgBAQABPyFOBNzoX4VaKJaXG3d6LJKXxD//2gAMAwEAAgADAAAAEJTf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEf/aAAgBAwEBPxBqaQ//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARFh/9oACAECAQE/EMVL/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFRYUGh0f/aAAgBAQABPxDw6Q7lncsiXBRmurgHZcu7KyEr38QgGThqvUeEdrP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2022年のRubyKaigiの様子",
            "title": "2022年のRubyKaigiの様子",
            "src": "/static/3d9c42bba6e39b8200cdab4f28de3885/e5166/rubykaigi2022.jpg",
            "srcSet": ["/static/3d9c42bba6e39b8200cdab4f28de3885/f93b5/rubykaigi2022.jpg 300w", "/static/3d9c42bba6e39b8200cdab4f28de3885/b4294/rubykaigi2022.jpg 600w", "/static/3d9c42bba6e39b8200cdab4f28de3885/e5166/rubykaigi2022.jpg 1200w", "/static/3d9c42bba6e39b8200cdab4f28de3885/d9c39/rubykaigi2022.jpg 1800w", "/static/3d9c42bba6e39b8200cdab4f28de3885/df51d/rubykaigi2022.jpg 2400w", "/static/3d9c42bba6e39b8200cdab4f28de3885/d2602/rubykaigi2022.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`2023年RubyKaigiの様子、会場の様子を見てきて欲しいと言われた若手エンジニアと。実はこの時写真を撮ってもらった人が、その後新卒としてメドレーに入るというミラクルが起きました`}</em>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a8ade353ed710721064465c78db39f92/d2602/rubykaigi2023.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQADBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFLAPrmmoGH/8QAGRABAAMBAQAAAAAAAAAAAAAAAgABAxEi/9oACAEBAAEFAm6MenFHpesO/p7u1//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAQQDAAAAAAAAAAAAAAAAARECEBIhIkFR/9oACAEBAAY/AtkYSn5amrtCyUJHB6P/xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhQTFRYYH/2gAIAQEAAT8hj3l8Lsi2+wjBaHUZWijvADY4a1H/2gAMAwEAAgADAAAAEPMP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/EM0MX//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGf/xAAdEAEAAwABBQAAAAAAAAAAAAABABEhMUFRkcHw/9oACAEBAAE/EA0dtHq+wdYhdG1+Q+4lvfcQSGExbtVxKnj3Tu3KgmW4aT//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2023年のRubyKaigiの様子",
            "title": "2023年のRubyKaigiの様子",
            "src": "/static/a8ade353ed710721064465c78db39f92/e5166/rubykaigi2023.jpg",
            "srcSet": ["/static/a8ade353ed710721064465c78db39f92/f93b5/rubykaigi2023.jpg 300w", "/static/a8ade353ed710721064465c78db39f92/b4294/rubykaigi2023.jpg 600w", "/static/a8ade353ed710721064465c78db39f92/e5166/rubykaigi2023.jpg 1200w", "/static/a8ade353ed710721064465c78db39f92/d9c39/rubykaigi2023.jpg 1800w", "/static/a8ade353ed710721064465c78db39f92/df51d/rubykaigi2023.jpg 2400w", "/static/a8ade353ed710721064465c78db39f92/d2602/rubykaigi2023.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`2024年RubyKaigiの様子、スポンサーブースにて`}</em>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fb5bd921976030e8a08cb525a48de2a1/d2602/IMG_3804.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABdyunNXiWIf8A/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIDBBEAEjH/2gAIAQEAAQUCPFfqw83aUBLV4iSn/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Bqv/EABYRAQEBAAAAAAAAAAAAAAAAAAABAv/aAAgBAgEBPwFMv//EABwQAAIBBQEAAAAAAAAAAAAAAAABQQIQESExkf/aAAgBAQAGPwLng5aNiK8wrf/EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRMXH/2gAIAQEAAT8htZZNzk6wbx03MnA/YpT6irLRXZU2Dez/2gAMAwEAAgADAAAAELPP/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBITH/2gAIAQMBAT8QVIm+H//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QBtDt/8QAHBABAAIDAAMAAAAAAAAAAAAAARExACFBUZGx/9oACAEBAAE/EClJZpW6OHfWBcBoENfdmFHpq375xh5eBuSIwjuJGyu3kodCCWgoz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024年のRubyKaigiの様子",
            "title": "2024年のRubyKaigiの様子",
            "src": "/static/fb5bd921976030e8a08cb525a48de2a1/e5166/IMG_3804.jpg",
            "srcSet": ["/static/fb5bd921976030e8a08cb525a48de2a1/f93b5/IMG_3804.jpg 300w", "/static/fb5bd921976030e8a08cb525a48de2a1/b4294/IMG_3804.jpg 600w", "/static/fb5bd921976030e8a08cb525a48de2a1/e5166/IMG_3804.jpg 1200w", "/static/fb5bd921976030e8a08cb525a48de2a1/d9c39/IMG_3804.jpg 1800w", "/static/fb5bd921976030e8a08cb525a48de2a1/df51d/IMG_3804.jpg 2400w", "/static/fb5bd921976030e8a08cb525a48de2a1/d2602/IMG_3804.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`社内でRubyのイベントを告知するようになってから、参加してくれる人も増えました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/24f7968ea0fd916c4d86216d9517047f/d8817/slack.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAABYlAAAWJQFJUiTwAAADIUlEQVQ4y5WVS2+bQBDH/cGbfo4krXrrpcceeskxVU6VUkUxfgIGA+b9fhqWfzXjLLKrVklWGg27w/5mxPx3mZnJgNvvv/D50y0+XF3h7u4OeZ4jiiL2TdNgHEcMw8D+/Jm8EAJy0PPMcFNcf/2B34+PuL//iaenJ2iqhtVqBV3Tsd/vYds2TMOE67r8bFkWm2EY8DwPuq7zvCxLzICR6RQ4VVXA931omobD4YCqqlDXNeq6QVlWKIqCjaqn9ePxiDAIeS9VPOPShUAcR9ibJjzXRRSGk8+yFEWeo+tatE3DyeI4YSglI3CWZTyfgKMQcPwIYZqj7nrkVYusrJFLqxoUdYskr5AVJUMIQNWRtW3Lnr8hAwEkYQzbcrDS9/CTAvVRoGh6VN2Aqu2RN0fkTY+2F2jqCl3b4l9jAjZ5ho1uYWvYWJoBdn4OLylxiAv4aQ03reBEBdykgpc2cNMaVdNBiAF930/dZiCNOM2w3KhYb1Qoqy0WaxVPyhLKWoVhHeC4Aba6gfVWg2E57DdblTvsOA7iOGYpTUD68AfHhu95WChzrJYLeO6B54HvI00S2NYeO13n9SDwuWkJrds2giC4rJA0RDpL0xS73Y5l8/w8x2azgWXZHFuv1zw3TRNhGDKI9ErapUovgNR6EjFlIhhB6aX5fM6mqirHCaYoCpbLJcPIKEYin7osgdvtlkGkNVI+VUXzxWLBIEpEnmIkejoMVAB9v67rLiskXVGQwFEcM1SKVeqMukkbycuzTOvnZ5qBNKHAxRCnRDLhW8bUZSFGHF9KHjDCel6hMdwTdxDTLfOaXVbY96csQrAU+mM/XUny5bdUewKOIxo6m12H4wtIJjqHvcVmkF1WnlFGEcI4hhiGd0EugCRo0tBcUVh3sjnn1b1nzOh2/vblBjfX17i6+oiHhwfYlsUiJh3Kq0p6MrpY6UTJdSqKjOQ1I01VeYY0TRAGAXzP5Qv0BDi9KDdKKP1nSK8ydgGUpTr2AWvNwM5ykcYRAo9u7NOJoRPBgo8i3vh6lwH4lg/dDaHRf4T+F1mILNgjjGLO/LeA/9eUP2AW3R6qHuSAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "omotesando.rb社内告知の様子",
            "title": "omotesando.rb社内告知の様子",
            "src": "/static/24f7968ea0fd916c4d86216d9517047f/c1b63/slack.png",
            "srcSet": ["/static/24f7968ea0fd916c4d86216d9517047f/5a46d/slack.png 300w", "/static/24f7968ea0fd916c4d86216d9517047f/0a47e/slack.png 600w", "/static/24f7968ea0fd916c4d86216d9517047f/c1b63/slack.png 1200w", "/static/24f7968ea0fd916c4d86216d9517047f/d8817/slack.png 1238w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`先日のKaigi on Railsでも、業務とは関係なく来たと言う人達で集まる一幕もあり、変化を感じた部分でもありました。`}</p>
    <p>{`もう一つは、やはり社外への認知で、今年のRubyKaigi 2024ではRubyスポンサーでブースを出していたのですが、「表参道.rbで会社に行ったことがあります」と教えてくださる人が数名いらっしゃったり、今年入社された方が「表参道.rbに参加したことがあります」と教えてくださったり、少なからずポジティブな側面があるなぁ、と感じることがありました。こういった声が定期的に聞けるのはスポンサードしている側としてはとても嬉しいです。`}</p>
    <h1>{`地域Rubyコミュニティのオーガナイザーから見た、メドレーと地域Rubyコミュニティとの関わり`}</h1>
    <p>{`さて、ここまではメドレーと地域Rubyコミュニティとの関わりについてお話してきました。`}<br parentName="p"></br>{`
`}{`ここからは私の所感について書きます。ここから先は私がオーガナイザーとして普段コミュニティ活動について考えていることですので、特段会社から何か言われているということではありません。誤解なきようお願いします（笑`}</p>
    <p>{`会社から見ると「地域.rbのスポンサードをしました！」、地域Rubyコミュニティのオーガナイザーから見ると「地域.rbを開催しました！」という話で終わるのですが、地域Rubyコミュニティのオーガナイザーをしつつ、会社として地域.rbの支援をする、という立場でみると、少し違った見え方になります。`}</p>
    <p>{`企業がこういったスポンサードを行う際には、一定の費用対効果を考える必要があるからです。`}<br parentName="p"></br>{`
`}{`私もスポンサードする側の立場から、そういった事情を抜きにすれば、「知名度向上のために積極的に行うべき」「採用面にも少なからずポジティブに働いている」と主張することはできますし、実際そういった側面はあると思います。しかし、それがどの程度プラスに働いているのかを正しく計測する方法がないため、基本的にはコミュニティはボランティアに近い形で支援してもらうことになります。`}<br parentName="p"></br>{`
`}{`そういったものは、長く続くとは限りませんし、普段ご支援いただいている会社の窓口担当の方や、決済責任者が変わるだけで状況は変わります。  `}</p>
    <p>{`これについては、オーガナイザーとしては仕方ないものと考えており、コミュニティを持続可能なものにするために、最大限努力をする必要があると考えています。`}</p>
    <p>{`以前表参道.rb 100回のときに、「表参道.rb 100回に寄せて」というタイトルで、「コミュニティが継続していくためにはどうすればよいか」というお話をさせていただきました。
その中で、コミュニティが継続するための3要素「主催者のやる気・スポンサード企業・参加者」の3つを挙げ、コミュニティが長く続くためにどうするべきか、というお話をさせていただきました。`}</p>
    <p><em parentName="p">{`「表参道.rb 100回に寄せて」`}</em>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/64a27d39e9dcd04fd4400a76b8e0e24f/0f98f/slide_image_010.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7Desmcv/xAAYEAACAwAAAAAAAAAAAAAAAAABEQAgIf/aAAgBAQABBQJaBFT/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/AiL/AP/EABoQAAIDAQEAAAAAAAAAAAAAAAERABAhUZH/2gAIAQEAAT8hOmDyIGIKIciFf//aAAwDAQACAAMAAAAQLy//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QkMv/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QrKz/xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhEDFRYXGR/9oACAEBAAE/EGCPekLREtCAlZUGsj3U4B5j/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "コミュニティが継続していくためには・主催者",
            "title": "コミュニティが継続していくためには・主催者",
            "src": "/static/64a27d39e9dcd04fd4400a76b8e0e24f/e5166/slide_image_010.jpg",
            "srcSet": ["/static/64a27d39e9dcd04fd4400a76b8e0e24f/f93b5/slide_image_010.jpg 300w", "/static/64a27d39e9dcd04fd4400a76b8e0e24f/b4294/slide_image_010.jpg 600w", "/static/64a27d39e9dcd04fd4400a76b8e0e24f/e5166/slide_image_010.jpg 1200w", "/static/64a27d39e9dcd04fd4400a76b8e0e24f/d9c39/slide_image_010.jpg 1800w", "/static/64a27d39e9dcd04fd4400a76b8e0e24f/0f98f/slide_image_010.jpg 1920w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d48c85c9a7ac6702816ddcc6c523f782/0f98f/slide_image_011.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7F2ZxRf/xAAXEAADAQAAAAAAAAAAAAAAAAAAEBFB/9oACAEBAAEFAtIov//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREQMUGx/9oACAEBAAE/IY00mxXvghERj//aAAwDAQACAAMAAAAQv+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QkMv/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QrKz/xAAaEAEAAwEBAQAAAAAAAAAAAAABABExIaHh/9oACAEBAAE/EAOCNuG/YVqErfIN1OwDCoFT/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "コミュニティが継続していくためには・会場",
            "title": "コミュニティが継続していくためには・会場",
            "src": "/static/d48c85c9a7ac6702816ddcc6c523f782/e5166/slide_image_011.jpg",
            "srcSet": ["/static/d48c85c9a7ac6702816ddcc6c523f782/f93b5/slide_image_011.jpg 300w", "/static/d48c85c9a7ac6702816ddcc6c523f782/b4294/slide_image_011.jpg 600w", "/static/d48c85c9a7ac6702816ddcc6c523f782/e5166/slide_image_011.jpg 1200w", "/static/d48c85c9a7ac6702816ddcc6c523f782/d9c39/slide_image_011.jpg 1800w", "/static/d48c85c9a7ac6702816ddcc6c523f782/0f98f/slide_image_011.jpg 1920w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/825657cd899935e8b9f006486f1aa2fb/0f98f/slide_image_012.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdhrJZUYX//EABgQAAIDAAAAAAAAAAAAAAAAAAARARAx/9oACAEBAAEFAskQr//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAQAGPwJr/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESFR/9oACAEBAAE/IawJI0WCnClH/9oADAMBAAIAAwAAABDM7//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCQy//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCsrP/EABoQAQEBAQADAAAAAAAAAAAAAAEAESExQZH/2gAIAQEAAT8Q9hTqh5gSGn2Z6S16hYIwIAML/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "コミュニティが継続していくためには・参加者",
            "title": "コミュニティが継続していくためには・参加者",
            "src": "/static/825657cd899935e8b9f006486f1aa2fb/e5166/slide_image_012.jpg",
            "srcSet": ["/static/825657cd899935e8b9f006486f1aa2fb/f93b5/slide_image_012.jpg 300w", "/static/825657cd899935e8b9f006486f1aa2fb/b4294/slide_image_012.jpg 600w", "/static/825657cd899935e8b9f006486f1aa2fb/e5166/slide_image_012.jpg 1200w", "/static/825657cd899935e8b9f006486f1aa2fb/d9c39/slide_image_012.jpg 1800w", "/static/825657cd899935e8b9f006486f1aa2fb/0f98f/slide_image_012.jpg 1920w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`メドレーも現在は地域Rubyコミュニティを無償で応援している立場ではありますが、これが未来永劫続くかは分かりません。が、可能な限り長期間支援できるように、一地域Rubyコミュニティのオーガナイザーとして、そして会社の中の人として、努力したいと思っています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`地域Rubyコミュニティのオーガナイザーから見た、メドレーと地域Rubyコミュニティとの関わりについて書かせていただきました。`}<br parentName="p"></br>{`
`}{`メドレーでは、表参道.rbと六本木.rbの地域Rubyコミュニティの支援をしており、定期的にメドレーでも開催しております。`}</p>
    <p>{`もし、ご興味ありましたら是非遊びに来てください。`}</p>
    <p>{`表参道.rb`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://omotesandorb.connpass.com/"
      }}>{`https://omotesandorb.connpass.com/`}</a></p>
    <p>{`六本木.rb`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://roppongirb.connpass.com/"
      }}>{`https://roppongirb.connpass.com/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      